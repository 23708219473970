.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(13, 42, 70,.8);
  border-right: 1.1em solid rgba(13, 42, 70, 0.8);
  border-bottom: 1.1em solid rgba(13, 42, 70, 0.8);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background: url('./Assets//background-img.png') no-repeat center center fixed;
  background-size: cover;
}

.styledButton {
  position: absolute;
  top: 20%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: all .4 linear;
}

.styledButton:focus {
  outline: none;
}

.styledButton:hover {
  transform: scale(1.1);
}

.styledButton > div {
  width: 2rem;
  height: 0.25rem;
  transition: all 0.3s ease;
  position: relative;
  transform-origin: 1px;
  background: white;
}

/* .styledButton > div:hover {
  background: black;
} */

.firstDiv {
  transform: rotate(0);
  background: white;
}

.secondDiv {
  opacity: 1;
  transform: translateX(0);
  background:white;
}

.thirdDiv {
  transform: rotate(0);
  background: white;
}

.openFirstDiv {
  transform: rotate(45deg);
  /* background: black; */
}

.openSecondDiv {
  opacity: 0;
  transform: translateX(20px);
}

.openThirdDiv {
  transform: rotate(-45deg);
  /* background: black; */
}

.navMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #3C658C;
  height: 100vh;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.9 ease;
  color: black;
  transform: translateX(0%);
}

.navMenuClosed {
  transform: translateX(100%);
  display: none;
}

.navMenu * {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5rem;
  text-decoration: none;
  margin: 20px;
  transition: color 0.3 linear;
}

.auctionWrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .auctionWrapper {
    flex-flow: row wrap;
  }
}


